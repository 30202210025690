<template>
    <v-container>
        <v-row>
            <v-col cols="2">
                <Menu></Menu>
            </v-col>
            <v-col v-if="!loading">
                <v-card class="mb-3">
                    <v-card-text>
                        <h2>Letzte Buchungen</h2>

                        <div v-for="(booking,index) in bookings" :key="booking.id">
                            <v-divider></v-divider>
                            <v-row>
                                <v-col>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ booking.hotel.name }},
                                            am {{ booking.date_from | formatDate('DD.MM.YYYY') }},
                                            {{ booking.travelers.length }} Personen
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-col>
                                <v-col class="text-right">
                                    <v-btn text color="success" @click="editBooking(index)">
                                        Ansehen
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="booking.edit" class="pb-2">
                                <v-col>
                                    <v-card>
                                        <v-card-text>
                                            <div>{{ booking.hotel.name }}</div>
                                            <div v-for="traveler in booking.travelers"
                                                 :key="traveler.id"
                                            >
                                                {{ traveler.lastname }} {{ traveler.firstname }}
                                            </div>
                                            <div>{{ booking.date_from | formatDate('DD.MM.YYYY') }}</div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col>
                                    <v-card>
                                        <v-card-text>
                                            <div class="mb-4">
                                                <a href="#">Rechnung herunterladen</a>
                                            </div>

                                            <v-row>
                                                <v-col><h2>Gesamtpreis</h2></v-col>
                                                <v-col class="text-right"><h2>XXX &euro;</h2></v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col class="text-right">
                                                    <v-btn color="primary">Neue Buchung mit Datenübernahme</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>

                        </div>
                        <v-divider></v-divider>

                        <v-row>
                            <v-col class="text-right mt-2">
                                <v-btn color="primary">Neue Reise buchen</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import Menu from "@/views/profile/menu";

export default {
    name: 'Bookings',
    components: {Menu},
    data: () => ({
        loading: true,
        bookings: [],
    }),
    mounted() {
        this.$store.dispatch('Profile/fetchBookings')
            .then(() => {
                this.bookings = this.getBookings();
            })
            .finally(() => {
                this.loading = false
                this.$root.$children[0].hideLoading()
            })
    },
    methods: {
        ...mapGetters('Profile', ['getBookings']),
        editBooking(index) {
            this.$set(this.bookings[index], 'edit', this.bookings[index].edit ? false : true)
        }
    },
}
</script>